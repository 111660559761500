export enum DataServiceType {
  http = 'HTTP',
  firebase = 'FIREBASE',
}

export const apiUrl = 'assets/misc/data.json';
// export const apiUrl = 'https://s3.amazonaws.com/appseed.io/mobile-apps/conference/data.json';

export const firebaseConfig = {
  apiKey: "AIzaSyB03P05T9XDrkPssiSYzaK-fXN3ml03QX0",
  authDomain: "vukati-mki.firebaseapp.com",
  databaseURL: "https://vukati-mki.firebaseio.com",
  projectId: "vukati-mki",
  storageBucket: "vukati-mki.appspot.com",
  messagingSenderId: "378702202427",
  appId: "1:378702202427:web:96e87ccc4cad0474fb7e1e",
  measurementId: "G-N53YXLQCWB"
};

export const DATA_SERVICE: DataServiceType = DataServiceType.http;
