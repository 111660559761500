import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../../services/common/user-data.service';
import { ignoreElements } from 'rxjs/operators';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.page.html',
  styleUrls: ['./tabs.page.scss'],
})
export class TabsPage implements OnInit {
  notloggedIn: boolean = false;
  constructor(private userDataService: UserDataService,) { }

  ngOnInit() {

    this.userDataService.isUserAuthenticated().subscribe((isUserAuthenticated) => {
      if (isUserAuthenticated) {
        this.notloggedIn = isUserAuthenticated
      }
    });

  }

}
