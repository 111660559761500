import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { TabsPage } from './tabs.page';
import { DataResolverService } from 'src/app/services/common/data-resolver.service';

const routes: Routes = [
  {
    path: 'tabs',
    component: TabsPage,
    children: [
      {
        path: 'home',
        loadChildren: () => import('../home/home.module').then((m) => m.HomeModule),
        resolve: { isCache: DataResolverService },
      },
      {
        path: 'schedule',
        loadChildren: () => import('../schedule/schedule.module').then((m) => m.ScheduleModule),
        resolve: { isCache: DataResolverService },
      },
      {
        path: 'realsponsors',
        loadChildren: () => import('../realsponsor/realsponsors.module').then((m) => m.RealsponsorsModule),
        resolve: { isCache: DataResolverService },
      },
      {
        path: 'exhibits',
        loadChildren: () => import('../exhibit/exhibits.module').then((m) => m.ExhibitsModule),
        resolve: { isCache: DataResolverService },
      },
      {
        path: 'chat',
        loadChildren: () => import('../chat/chat.module').then((m) => m.ChatModule),
        resolve: { isCache: DataResolverService },
      },
      {
        path: 'map',
        loadChildren: () => import('../map/map.module').then((m) => m.MapPageModule),
        resolve: { isCache: DataResolverService },
      },
      {
        path: 'about',
        loadChildren: () => import('../about/about.module').then((m) => m.AboutPageModule),
        resolve: { isCache: DataResolverService },
      },

      {
        path: 'abstracts',
        loadChildren: () => import('../abstracts/abstracts.module').then((m) => m.AbstractsModule),
        resolve: { isCache: DataResolverService },
      },
      {
        path: 'events',
        loadChildren: () => import('../events/events.module').then((m) => m.EventsModule),
        resolve: { isCache: DataResolverService },
      },
      //{
      //  path: 'chat',
      //  loadChildren: () => import('../chat/chat.module').then((m) => m.ChatModule),
      //  resolve: { isCache: DataResolverService },
      //},
      {
        path: 'committees',
        loadChildren: () => import('../committees/committees.module').then((m) => m.CommitteesModule),
        resolve: { isCache: DataResolverService },
      },
      {
        path: 'speakers',
        loadChildren: () => import('../speakers/speakers.module').then((m) => m.SpeakersModule),
        resolve: { isCache: DataResolverService },
      },
      {
        path: 'sponsors',
        loadChildren: () => import('../sponsors/sponsors.module').then((m) => m.SponsorsModule),
        resolve: { isCache: DataResolverService },
      }, 
      {
        path: 'managehs',
        loadChildren: () => import('../managehs/managehs.module').then((m) => m.ManagehsPageModule),
      },
      {
        path: 'addevent',
        loadChildren: () => import('../events/addevent/addevent.module').then((m) => m.AddEventPageModule),
      },
      {
        path: 'addschedule',
        loadChildren: () => import('../addschedule/addschedule.module').then((m) => m.AddSchedulePageModule),
      }, 
      {
        path: 'account',
        loadChildren: () => import('../account/account.module').then((m) => m.AccountPageModule),
      },

      {
        path: '',
        redirectTo: '/tabs/home',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
  ],
  declarations: [TabsPage],
})
export class TabsPageModule {}
