import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, NavController, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UserDataService } from './services/common/user-data.service';
import { InAppBrowserService } from './services/common/in-app-browser.service';
import { EventsService } from './services/common/events.service';
import { Storage } from '@ionic/storage';
import { Observable, Subscription } from 'rxjs';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  appPagesOut: PageInterface[] = [

    
  ];

  appPages: PageInterface[] = [
    { title: 'Home', name: 'TabsPage', path: 'tabs/home', index: 0, icon: 'home' },

    { title: 'Schedule', name: 'TabsPage', path: 'tabs/schedule', index: 0, icon: 'calendar' },
    { title: 'Events', name: 'EventsPage', path: 'tabs/events', icon: 'alarm' },
    { title: 'Chat', name: 'TabsPage', path: 'tabs/chat', index: 1, icon: 'chatbubbles' },
 
    { title: 'Social Feeds', name: 'AbstractsPage', path: 'tabs/abstracts', icon: 'logo-rss' },
    
    { title: 'Speakers', name: 'TabsPage', path: 'tabs/speakers', index: 1, icon: 'contacts' },
    { title: 'Manage Home Screen', name: 'ManagehsPage', path: 'tabs/managehs', icon: 'planet' },
    { title: 'Manage Admins', name: 'SponsorsPage', path: 'tabs/sponsors', icon: 'briefcase' },
    { title: 'Map', name: 'TabsPage', path: 'tabs/map', index: 2, icon: 'map' },
    { title: 'About', name: 'TabsPage', path: 'tabs/about', index: 3, icon: 'information-circle' },
  ];

  loggedInPages: PageInterface[] = [
    { title: 'Account', name: 'TabsPage', path: 'tabs/account', icon: 'person' },
    { title: 'Logout', name: 'TabsPage', path: 'logout', icon: 'log-out', logsOut: true },
  ];

  loggedOutPages: PageInterface[] = [
    { title: 'Login', name: 'LoginPage', path: 'login', icon: 'log-in' },
    { title: 'Reset Password', name: 'ResetPage', path: 'reset', icon: 'refresh-circle' },
    { title: 'Signup', name: 'SignUpPage', path: 'sign-up', icon: 'person-add' },
  ];
  role: string = '2';
  email: string = '';
  chat: any = [];
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navCtrl: NavController,
    private userDataService: UserDataService,
    private menu: MenuController,
    private inAppBrowserService: InAppBrowserService,
    private router: Router,
    private eventsService: EventsService,
    private storage: Storage,
    private chatService: ChatService,
  ) {
    
    this.initializeApp();
  }

  private chatsubscription: Subscription = new Subscription();

  ngOnDestroy() {
    this.chatsubscription.unsubscribe();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      setTimeout(() => {
        this.splashScreen.hide();
      }, 1000);
    });

    // decide which menu items should be hidden by current login status stored in local storage
    this.userDataService.isUserAuthenticated().subscribe((isUserAuthenticated) => {
      

      this.chatsubscription = this.chatService.getChatsUsers().subscribe(async (chat: any[]) => {
        
          this.storage.get('uemail').then(async (data) => {
            this.email = data;
            try {
              this.chat = chat.filter(item => item.email == this.email)
              if (this.email.length > 0) {
                console.log(this.chat[0].role && this.chat, length > 0);
                if (this.chat[0].role == '2') {
                  this.appPages = this.appPages.filter(i => i.title != 'Events');
                  this.appPages = this.appPages.filter(i => i.title != 'Manage Home Screen');
                  this.appPages = this.appPages.filter(i => i.title != 'Manage Admins');

                  await this.enableMenu(isUserAuthenticated);
                  this.chatsubscription.unsubscribe();
                }
              }
            } catch (exc) { }
             
          });
        
        this.chatsubscription.unsubscribe();
      });
    });

    this.listenToLoginEvents();
  }

  openPage(page: PageInterface) {
    if (page.logsOut === true) {
      // Give the menu time to close before changing to logged out
      this.userDataService.signOut();
      this.navCtrl.navigateRoot('/tutorial');
      this.eventsService.userLoggedOut(false);


    } else {
      this.navCtrl.navigateRoot('tabs/account');
    }
  }

  openPrivacyPolicy(page: string) {
    this.inAppBrowserService.open(page);
  }

  isActive(p: PageInterface) {
    return this.router.isActive(p.path, true) ? 'primary' : null;
  }

  listenToLoginEvents() {
    this.eventsService.onUserLogin.subscribe((isMenuEnabled) => {
      this.enableMenu(isMenuEnabled);
    });

    this.eventsService.onUserSignUp.subscribe((isMenuEnabled) => {
      this.enableMenu(isMenuEnabled);
    });

    this.eventsService.onUserLogOut.subscribe((isMenuEnabled) => {
      this.enableMenu(isMenuEnabled);
    });
  }

  async enableMenu(loggedIn: boolean) {
    await this.menu.enable(loggedIn, 'loggedInMenu');
    await this.menu.enable(!loggedIn, 'loggedOutMenu');
  }
}

interface PageInterface {
  title: string;
  name: string;
  path: string;
  icon: string;
  logsOut?: boolean;
  index?: number;
  tabName?: string;
  tabComponent?: any;
}
