import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Chat {
  id: string;
  touser: string;
  fromuser: string;
  fromuserName: string;
  userAvatar: string;
  time: string;
  message: string;
}


export interface ChatUser {
  id: string;
  name: string;
  surname: string;
  phone: string;
  email: string;
  image: string; 
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private todosCollection: AngularFirestoreCollection<Chat>;
  private currentChat: any;
  private todos: Observable<Chat[]>;

  private todosCollectionusers: AngularFirestoreCollection<ChatUser>;
  private todosusers: Observable<ChatUser[]>;

  private todosCollectionuserslist: AngularFirestoreCollection<ChatUser>;
  private todosuserslist: Observable<ChatUser[]>;
 
  constructor(db: AngularFirestore) {
    this.todosCollection = db.collection<Chat>('chats');
 
    this.todos = this.todosCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );

    this.todosCollectionusers = db.collection<ChatUser>('users');

    this.todosusers = this.todosCollectionusers.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );


    this.todosCollectionuserslist = db.collection<ChatUser>('users');

    this.todosuserslist = this.todosCollectionuserslist.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }
 
  getChats() {
    return this.todos;
  }

  getChatsUsers() {
    return this.todosusers;
  }

  getChatsUserslist() {
    return this.todosuserslist;
  }
 
  getChat(id) {
    return this.todosCollection.doc<Chat>(id).valueChanges();
  }
 
  updateChat(chat: Chat, id: string) {
    return this.todosCollection.doc(id).update(chat);
  }
 
  addChat(chat: Chat) {
    return this.todosCollection.add(chat);
  }
 
  removeChat(id) {
    return this.todosCollection.doc(id).delete();
  }


  setCurrent(chat: any) {
    this.currentChat = chat;
  }

  getCurrent() {
    return this.currentChat;
  }
}
